.Timetable-tt-drawer {
	padding: 16px;
}
.Timetable-tt-drawer-fab-div {
	position: absolute;
	top: -28px;
	right: 32px;
}
.Timetable-tt-drawer-input-div {
	margin-bottom: 12px;
}
.Timetable-tt-button {
	display: block !important;
	padding: 16px !important;
	height: 80px !important;
	border: .5px solid rgb(20, 20, 20) !important;
	border-collapse: collapse !important;
}
.Timetable-tt-button-wd {
	height: 48px !important;
	background-color: rgb(29, 29, 29) !important;
}
.Timetable-tt-button-invis {
	color: transparent;
}
.Timetable-tt-button-prop {
	display: block;
}
.Timetable-tt-div {
	display: flex;
	flex-direction: row;
}
.Timetable-tt-row {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}
.Timetable-tt-col {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
}